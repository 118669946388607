import React, { useState } from "react";
import InputTextBox from "../../controls/input/InputTextBox";
import AccordionCard from "../../controls/accordion/AccordionCard";

// main function
const ParticipantFormDetails = ({ event }) => {
  //declare start
  const [activeTabNo, setActiveTabNo] = useState(0);

  //declare end

  if (!event || !event.participantForm)
    return (
      <div className="card-body p-1">
        Participant Form Details are not available for {event.eventName} !!
      </div>
    );

  const { participantForm } = event;

  //accordion
  const handleSave = (tabNo) => {
    setActiveTabNo(() => tabNo + 1);
  };
  const handleSkip = (tabNo) => {
    setActiveTabNo(() => tabNo + 1);
  };

  const config = {
    activeTabNo: activeTabNo,
    data: [
      {
        title: (
          <div className="d-flex justify-content-center align-items-center accordion-detail">
            <h1> Id Proof</h1>
          </div>
        ),

        content: <IdProof participantForm={participantForm} />,
      },
      {
        title: (
          <div className="d-flex justify-content-center align-items-center accordion-detail">
            <h1>TimingCertificate </h1>
          </div>
        ),

        content: <TimingCertificate participantForm={participantForm} />,
      },
      {
        title: (
          <div className="d-flex justify-content-center align-items-center accordion-detail ">
            <h1> Emergency Contact</h1>
          </div>
        ),

        content: <EmergencyContact participantForm={participantForm} />,
      },
      {
        title: (
          <div className="d-flex justify-content-center align-items-center accordion-detail ">
            <h1> Medical Information</h1>
          </div>
        ),

        content: <MedicalInformation participantForm={participantForm} />,
      },
    ],
  };

  //main return
  return (
    <>
      <div className="card-body p-1">
        <div
          className="accordion accordion-flush m-0"
          id="accordionFlushExample"
        >
          <div>
            {/* <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#participant-collapseThree"
                aria-expanded="false"
                aria-controls="participant-collapseThree"
              >
                <div className="d-flex justify-content-center align-items-center accordion-detail">
                  <h1>Participant Form Details</h1>
                </div>
              </button>
            </h2> */}
            <div
              id="participant-collapseThree"
              className="accordion-collapse collapse accordion-detail px-4 show"
              data-bs-parent="#accordionFlushExample"
            >
              <form className="row g-3 pb-3">
                {/* Display fields from the participantForm object */}
                <div className="col-md-6 mb-3">
                  <label htmlFor="isName" className="form-label">
                    Participant Name
                  </label>
                  <InputTextBox
                    id="isName"
                    name="isName"
                    type="text"
                    value={participantForm.isName ? "Yes" : "No"}
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="isEmail" className="form-label">
                    Email
                  </label>
                  <InputTextBox
                    id="isEmail"
                    name="isEmail"
                    type="text"
                    value={participantForm.isEmail ? "Yes" : "No"}
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="isMobile" className="form-label">
                    Mobile
                  </label>
                  <InputTextBox
                    id="isMobile"
                    name="isMobile"
                    type="text"
                    value={participantForm.isMobile ? "Yes" : "No"}
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="isDob" className="form-label">
                    Date of Birth
                  </label>
                  <InputTextBox
                    id="isDob"
                    name="isDob"
                    type="text"
                    value={
                      participantForm.isDob && participantForm.isDob.isActive
                        ? "Yes"
                        : "No"
                    }
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="isGender" className="form-label">
                    Gender
                  </label>
                  <InputTextBox
                    id="isGender"
                    name="isGender"
                    type="text"
                    value={
                      participantForm.isGender &&
                      participantForm.isGender.isActive
                        ? "Yes"
                        : "No"
                    }
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="isUploadPhoto" className="form-label">
                    Upload Photo
                  </label>
                  <InputTextBox
                    id="isUploadPhoto"
                    name="isUploadPhoto"
                    type="text"
                    value={
                      participantForm.isUploadPhoto &&
                      participantForm.isUploadPhoto.isActive
                        ? "Yes"
                        : "No"
                    }
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="isTshirtSize" className="form-label">
                    T-shirt Size
                  </label>
                  <InputTextBox
                    id="isTshirtSize"
                    name="isTshirtSize"
                    type="text"
                    value={
                      participantForm.isTshirtSize &&
                      participantForm.isTshirtSize.isActive
                        ? "Yes"
                        : "No"
                    }
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="isClubName" className="form-label">
                    Name of Club
                  </label>
                  <InputTextBox
                    id="isClubName"
                    name="isClubName"
                    type="text"
                    value={
                      participantForm.isClubName &&
                      participantForm.isClubName.isActive
                        ? "Yes"
                        : "No"
                    }
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="isCountry" className="form-label">
                    Country
                  </label>
                  <InputTextBox
                    id="isCountry"
                    name="isCountry"
                    type="text"
                    value={
                      participantForm.isCountry &&
                      participantForm.isCountry.isActive
                        ? "Yes"
                        : "No"
                    }
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="isState" className="form-label">
                    State
                  </label>
                  <InputTextBox
                    id="isState"
                    name="isState"
                    type="text"
                    value={
                      participantForm.isState &&
                      participantForm.isState.isActive
                        ? "Yes"
                        : "No"
                    }
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="isCity" className="form-label">
                    City
                  </label>
                  <InputTextBox
                    id="isCity"
                    name="isCity"
                    type="text"
                    value={
                      participantForm.isCity && participantForm.isCity.isActive
                        ? "Yes"
                        : "No"
                    }
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="isAddress" className="form-label">
                    Address
                  </label>
                  <InputTextBox
                    id="isAddress"
                    name="isAddress"
                    type="text"
                    value={
                      participantForm.isAddress &&
                      participantForm.isAddress.isActive
                        ? "Yes"
                        : "No"
                    }
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="isPincode" className="form-label">
                    Pincode
                  </label>
                  <InputTextBox
                    id="isPincode"
                    name="isPincode"
                    type="text"
                    value={
                      participantForm.isPincode &&
                      participantForm.isPincode.isActive
                        ? "Yes"
                        : "No"
                    }
                    disabled
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="isGroupName" className="form-label">
                    Group Name
                  </label>
                  <InputTextBox
                    id="isGroupName"
                    name="isGroupName"
                    type="text"
                    value={
                      participantForm?.isGroupName &&
                      participantForm?.isGroupName?.isActive
                        ? "Yes"
                        : "No"
                    }
                    disabled
                  />
                </div>
              </form>
              <AccordionCard config={config} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const IdProof = ({ participantForm }) => {
  return (
    <>
      <div className=" card mb-2" id="accordionIDProof">
        <div className="accordion-item">
          <div
            id="IDProofCollapse"
            className="accordion-collapse collapse show"
            aria-labelledby="IDProofHeader"
            data-bs-parent="#accordionIDProof"
          >
            <div className="accordion-body">
              <form className="row g-3 pb-3">
                <p>No Data Available for ID Proof.</p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const TimingCertificate = ({ participantForm }) => {
  return (
    <>
      <div
        className="accordion accordion-flush m-0 card mb-2"
        id="accordionTimingCertificate"
      >
        <div className="accordion-item">
          {/* <h2 className="accordion-header" id="timingCertificateHeader">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#timingCertificateCollapse"
                        aria-expanded="true"
                        aria-controls="timingCertificateCollapse"
                      >
                         <div className="d-flex justify-content-center align-items-center accordion-detail ">
                  <h1>  Timing Certificate</h1>
                </div>  
                       
                      </button>
                    </h2> */}
          <div
            id="timingCertificateCollapse"
            className="accordion-collapse collapse show"
            aria-labelledby="timingCertificateHeader"
            data-bs-parent="#accordionTimingCertificate"
          >
            <div className="accordion-body">
              <form className="row g-3 pb-3">
                <div className="col-md-6 mb-3">
                  <label htmlFor="isTimingCertificate" className="form-label">
                    Timing Certificate
                  </label>
                  <InputTextBox
                    id="isTimingCertificate"
                    name="isTimingCertificate"
                    type="text"
                    value={
                      participantForm.isTimingCertificate &&
                      participantForm.isTimingCertificate.isActive
                        ? "Yes"
                        : "No"
                    }
                    disabled
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const EmergencyContact = ({ participantForm }) => {
  return (
    <>
      <div
        className="accordion accordion-flush m-0 card mb-2"
        id="accordionEmergencyContact"
      >
        <div className="accordion-item">
          {/* <h2 className="accordion-header" id="emergencyContactHeader">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#emergencyContactCollapse"
                        aria-expanded="true"
                        aria-controls="emergencyContactCollapse"
                      >
                        <div className="d-flex justify-content-center align-items-center accordion-detail">
                  <h1>  Emergency Contact</h1>
                </div>
                       
                      </button>
                    </h2> */}

          <div
            id="emergencyContactCollapse"
            className="accordion-collapse collapse show"
            aria-labelledby="emergencyContactHeader"
            data-bs-parent="#accordionEmergencyContact"
          >
            <div className="accordion-body">
              <form className="row g-3 pb-3">
                <div className="col-md-6 mb-3">
                  <label htmlFor="isEmergencyContact" className="form-label">
                    Emergency Contact
                  </label>
                  <InputTextBox
                    id="isEmergencyContact"
                    name="isEmergencyContact"
                    type="text"
                    value={
                      participantForm.isEmergencyContact &&
                      participantForm.isEmergencyContact.isActive
                        ? "Yes"
                        : "No"
                    }
                    disabled
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const MedicalInformation = ({ participantForm }) => {
  return (
    <>
      <div
        className="accordion accordion-flush m-0 card mb-2"
        id="accordionMedicalInfo"
      >
        <div className="accordion-item">
          {/* <h2 className="accordion-header" id="medicalInfoHeader">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#medicalInfoCollapse"
                        aria-expanded="true"
                        aria-controls="medicalInfoCollapse"
                      >
                        <div className="d-flex justify-content-center align-items-center accordion-detail">
                  <h1> Medical Information</h1>
                </div>
                        
                      </button>
                    </h2> */}
          <div
            id="medicalInfoCollapse"
            className="accordion-collapse collapse show"
            aria-labelledby="medicalInfoHeader"
            data-bs-parent="#accordionMedicalInfo"
          >
            <div className="accordion-body">
              <form className="row g-3 pb-3">
                <div className="col-md-6 mb-3">
                  <label htmlFor="isFitnessCertificate" className="form-label">
                    Fitness Certificate
                  </label>
                  <InputTextBox
                    id="isFitnessCertificate"
                    name="isFitnessCertificate"
                    type="text"
                    value={
                      participantForm.isFitnessCertificate &&
                      participantForm.isFitnessCertificate.isActive
                        ? "Yes"
                        : "No"
                    }
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="isBloodGroup" className="form-label">
                    Blood Group
                  </label>
                  <InputTextBox
                    id="isBloodGroup"
                    name="isBloodGroup"
                    type="text"
                    value={
                      participantForm.isBloodGroup &&
                      participantForm.isBloodGroup.isActive
                        ? "Yes"
                        : "No"
                    }
                    disabled
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParticipantFormDetails;
