import { takeEvery, put, call } from "redux-saga/effects";
import * as actions from "../actions/createEvent";
import { GetEventDetailsData } from "../../service/eventAPI";
//eventDetails-start
//EventDetails worker sagas
function* fetchEventDetailsWorker(action) {
  // console.log("saga call for fetchEventDetailsWorker");
  try {
    const EventDetails = yield call(GetEventDetailsData, action.payload);
    //"success": true,
    if (EventDetails?.success && EventDetails?.data) {
      yield put(actions.fetchEventDetailsSuccess(EventDetails?.data));
    }
  } catch (error) {
    console.error(error);
  }
}

function* addEventDetailsWorker(action) {
  // console.log("saga call for addEventDetailsWorker ");
  console.log(action);
  try {
    /*   const EventDetails = yield call(
      eventAPI.SaveAddToEventDetails,
      action.payload
    ); */
    yield put(actions.saveEventDetailsSuccess(action.payload));
  } catch (error) {
    console.error(error);
  }
}

function* deleteEventDetailsWorker(action) {
  try {
    //yield call(eventAPI.deleteEventDetails, action.payload);
    yield put(actions.deleteEventDetailsSuccess(action.payload));
  } catch (error) {
    console.error(error);
  }
}

//eventDetails Watcher Sagas
export function* watchFetchEventDetails() {
  // console.log("watchFetchEventDetails saga call2");
  yield takeEvery(actions.FETCH_EVENT_DETAILS_REQUEST, fetchEventDetailsWorker);
}

export function* watchAddEventDetails() {
  // console.log("watchAddEventDetails saga call2");
  yield takeEvery(actions.ADD_EVENT_DETAILS_REQUEST, addEventDetailsWorker);
}

export function* watchDeleteEventDetails() {
  yield takeEvery(
    actions.DELETE_EVENT_DETAILS_REQUEST,
    deleteEventDetailsWorker
  );
}
//eventDetails-end
/* 
//EventLocation-start
//EventLocation worker sagas
function* fetchEventLocationWorker(action) {
  // console.log("saga call for fetchEventLocationWorker");
  try {
    //const EventLocation = yield call(eventAPI.GetEventLocation);
    yield put(actions.fetchEventLocationSuccess(action.payload));
  } catch (error) {
    console.error(error);
  }
}

function* addEventLocationWorker(action) {
  // console.log("saga call for addEventLocationWorker ");
  console.log(action);
  try {
    /*   const EventLocation = yield call(
      eventAPI.SaveAddToEventLocation,
      action.payload
    ); 
    yield put(actions.saveEventLocationSuccess(action.payload));
  } catch (error) {
    console.error(error);
  }
}

function* deleteEventLocationWorker(action) {
  try {
    //yield call(eventAPI.deleteEventLocation, action.payload);
    yield put(actions.deleteEventLocationSuccess(action.payload));
  } catch (error) {
    console.error(error);
  }
}

//EventLocation Watcher Sagas
export function* watchFetchEventLocation() {
  // console.log("watchFetchEventLocation saga call2");
  yield takeEvery(
    actions.FETCH_EVENT_LOCATION_REQUEST,
    fetchEventLocationWorker
  );
}

export function* watchAddEventLocation() {
  // console.log("watchAddEventLocation saga call2");
  yield takeEvery(actions.ADD_EVENT_LOCATION_REQUEST, addEventLocationWorker);
}

export function* watchDeleteEventLocation() {
  yield takeEvery(
    actions.DELETE_EVENT_LOCATION_REQUEST,
    deleteEventLocationWorker
  );
}
//EventLocation-end

//EventPhotos-start
//EventPhotos worker sagas
function* fetchEventPhotosWorker(action) {
  // console.log("saga call for fetchEventPhotosWorker");
  try {
    //const EventPhotos = yield call(eventAPI.GetEventPhotos);
    yield put(actions.fetchEventPhotosSuccess(action.payload));
  } catch (error) {
    console.error(error);
  }
}

function* addEventPhotosWorker(action) {
  // console.log("saga call for addEventPhotosWorker ");
  console.log(action);
  try {
    /*   const EventPhotos = yield call(
      eventAPI.SaveAddToEventPhotos,
      action.payload
    ); 
    yield put(actions.saveEventPhotosSuccess(action.payload));
  } catch (error) {
    console.error(error);
  }
}

function* deleteEventPhotosWorker(action) {
  try {
    //yield call(eventAPI.deleteEventPhotos, action.payload);
    yield put(actions.deleteEventPhotosSuccess(action.payload));
  } catch (error) {
    console.error(error);
  }
}

//EventPhotos Watcher Sagas
export function* watchFetchEventPhotos() {
  // console.log("watchFetchEventPhotos saga call2");
  yield takeEvery(actions.FETCH_EVENT_PHOTOS_REQUEST, fetchEventPhotosWorker);
}

export function* watchAddEventPhotos() {
  // console.log("watchAddEventPhotos saga call2");
  yield takeEvery(actions.ADD_EVENT_PHOTOS_REQUEST, addEventPhotosWorker);
}

export function* watchDeleteEventPhotos() {
  yield takeEvery(actions.DELETE_EVENT_PHOTOS_REQUEST, deleteEventPhotosWorker);
}
//EventPhotos-end

//TicketDetails-start
//TicketDetails worker sagas
function* fetchTicketDetailsWorker(action) {
  // console.log("saga call for fetchTicketDetailsWorker");
  try {
    //const TicketDetails = yield call(eventAPI.GetTicketDetails);
    yield put(actions.fetchTicketDetailsSuccess(action.payload));
  } catch (error) {
    console.error(error);
  }
}

function* addTicketDetailsWorker(action) {
  // console.log("saga call for addTicketDetailsWorker ");
  // console.log(action);
  try {
    /*   const TicketDetails = yield call(
      eventAPI.SaveAddToTicketDetails,
      action.payload
    ); 
    yield put(actions.saveTicketDetailsSuccess(action.payload));
  } catch (error) {
    console.error(error);
  }
}

function* deleteTicketDetailsWorker(action) {
  try {
    //yield call(eventAPI.deleteTicketDetails, action.payload);
    yield put(actions.deleteTicketDetailsSuccess(action.payload));
  } catch (error) {
    console.error(error);
  }
}

//TicketDetails Watcher Sagas
export function* watchFetchTicketDetails() {
  // console.log("watchFetchTicketDetails saga call2");
  yield takeEvery(
    actions.FETCH_TICKET_DETAILS_REQUEST,
    fetchTicketDetailsWorker
  );
}

export function* watchAddTicketDetails() {
  // console.log("watchAddTicketDetails saga call2");
  yield takeEvery(actions.ADD_TICKET_DETAILS_REQUEST, addTicketDetailsWorker);
}

export function* watchDeleteTicketDetails() {
  yield takeEvery(
    actions.DELETE_TICKET_DETAILS_REQUEST,
    deleteTicketDetailsWorker
  );
}
//TicketDetails-end
 */
