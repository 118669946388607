import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Table from "../controls/table/Table";
import {
  EventActiveOrDeactiveByUserId,
  GetAllTotelEventByUserId,
  GetOragnizerList,
} from "../../service/eventAPI.jsx";
import { GetStoreLoginData } from "../../service/commonAPI";
import {
  NO_IMAGE_PATH,
  FOLDER_EVENT_PHOTO,
  KEY_EVENT_ID,
} from "../../util/Constants";
import Spinner from "../controls/spinner/Spinner.jsx";
import Modal from "../../components/controls/popup/Modal.jsx";

//main function
const TotalEvents = () => {
  //declare start
  const [allEvents, setAllEvents] = useState([]);
  const [oragnizerList, setOragnizerList] = useState([]);
  const [refUserId, setRefUserId] = useState("0");
  //const userData = useSelector((loginData) => loginData.login.login);
  const userData = GetStoreLoginData();
  // const userData = useSelector((state) => state.login.login.login);
  // const navigate = useNavigate();
  let ImagePath = process.env.REACT_APP_EVENTS;
  //const [showData, setShowData] = useState(1);
  const [showActivePopup, setShowActivePopup] = useState(false);
  const [loading, setLoading] = useState(true); //spinner
  const [currentEvent, setCurrentEvent] = useState(null); //for cancel event it should give correct event data
  const [loadingCancel, setLoadingcancel] = useState(false); // event cancel loading

  //declare end

  //funtion/method/event
  useEffect(() => {
    const oragList = async () => {     
      try {
        const allEventsResponse = await GetOragnizerList(userData?.token);
        if (allEventsResponse?.success === true) {
          // setLoading(false);
          setOragnizerList(allEventsResponse?.data);
        }
      } catch (error) {
        console.log(error);        
      }
      finally{
        setLoading(false);
      }
    };

    oragList();
  }, []);

  useEffect(() => {
    const getAllEvents = async () => {
      try {
        if (refUserId !== "0") {
          const allEventsResponse = await GetAllTotelEventByUserId(
            userData?.token,
            refUserId
          );
          if (allEventsResponse?.success === true) {
            setAllEvents(allEventsResponse?.data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    getAllEvents();
  }, [refUserId]);

  //configration
  const config = {
    tableTitle: "All Events",
    data: allEvents,
    displayRecord: 10,
    columns: [
      {
        title: "Photo",
        titleClass: "text-left",
        dataClass: "py-1 images align-middle",
        isImage: true,
        render: (item) => handleImagepath(item),
      },
      {
        title: "Name ",
        titleClass: " ",
        dataClass: "",
        dataField: "eventName",
        isSort: false,
        sortByValue: (data) => data.eventName,
      },
      {
        title: "Event Id",
        titleClass: "",
        dataClass: "",
        dataField: "eventId",
        // isSort: false,
        // sortByValue: (data) => data.eventId,
      },
      {
        title: "Start Date",
        titleClass: "",
        dataClass: "text-left",
        dataField: "eventStartDate2",
        isSort: false,
        sortByValue: (data) => data.eventStartDate,
      },

      {
        title: "Status",
        titleClass: "",
        dataClass: "text-left align-middle text-success-btn",
        dataField: "eventStatus2",
        isSort: false,
        sortByValue: (data) => data.eventStatus2,
      },

      {
        title: "View",
        isLink: true,
        render: (item) => (
          <div class="text-left align-middle">
            <Link
              className="btn-link btn align-middle p-0"
              to={`/event-details/${item.eventId}`}
            >
              View
            </Link>
          </div>
        ),
        titleClass: "",
        dataClass: "text-left",
      },
      {
        title: "Action",
        isLink: true,
        render: (item) => {
          if (item?.eventStatus2 === "Active") {
            return (
              <>
                <div class="text-left align-middle">
                  <a
                    class="btn-link btn align-middle p-0"
                    href="#"
                    onClick={() => handleAction(item)}
                  >
                    {item?.eventStatus2 === "Active" ? "Cancel" : ""}
                  </a>
                </div>
              </>
            );
          }
        },
        titleClass: "",
        dataClass: "text-left",
      },
    ],
  };

  const handleAction = (item) => {
    setCurrentEvent(item);
    setShowActivePopup(() => true);
  };

  const handleActionClose = () => {
    setShowActivePopup(() => false);
    setCurrentEvent(null);
  };

  const handleActionSuccess = (item) => {
    //api call for deactivation/ activation
    setLoadingcancel(true);
    const getAllEvents = async () => {
      try {
        const allEventsResponse = await EventActiveOrDeactiveByUserId(
          userData?.token,
          currentEvent?.eventId,
          refUserId
        );

        if (allEventsResponse?.success === true) {
          setLoadingcancel(false);
          handleActionClose();
          alert(allEventsResponse?.message);
        } else if (allEventsResponse?.success === false) {
          setLoadingcancel(false);
          handleActionClose();
          alert(allEventsResponse?.message);
        }
      } catch (error) {
        setLoadingcancel(false);
        handleActionClose();
        alert("Error with cancelling event", error);
      }
    };
    getAllEvents();
  };

  const handleImagepath = (item) => {
    let ImageFullPath =
      ImagePath + item.eventId + FOLDER_EVENT_PHOTO + item?.eventPhotos;

    return (
      <img
        src={ImageFullPath}
        class="img-fluid rounded"
        style={{ maxWidth: "55px", maxHeight: "45px" }}
      />
    );
  };

  const handleOragnizer = (event) => {
    const { value } = event.target;
    setRefUserId(() => value);
  };

  //main return
  return (
    <>
      <div className="row">
        <div className="col-4 m-2 mt-2">&nbsp;</div>
        <div className="col-4 m-2 mt-2">
          <select
            class="form-select form-select-lg mb-3 custom-select-font"
            onChange={(event) => handleOragnizer(event)}
            value={refUserId}
          >
            <option value="0" className="custom-placeholder">
              Please Select Organizer
            </option>
            {oragnizerList &&
              oragnizerList?.map((item, index) => {
                return (
                  <option value={item?.userId}>{item?.businessName}</option>
                );
              })}
          </select>
        </div>
      </div>
      {loading ? <Spinner /> : <Table config={config} />}
      {/*  {showData == 1 && <Table config={config} />}
      {showData == 2 && <ViewSalesDetails config={config} />} */}

      {showActivePopup && currentEvent && (
        <Modal
          title={`Event-${currentEvent?.eventId}`}
          onClose={handleActionClose}
          onSuccess={() => handleActionSuccess(currentEvent)}
          successText={
            currentEvent?.eventStatus2 === "Active"
              ? "Cancel"
              : currentEvent?.eventStatus2 === "Cancel"
              ? ""
              : ""
          }
        >
          {loadingCancel ? (
            <span
              className="loaderBreakup"
              style={{ marginLeft: "110px" }}
            ></span>
          ) : (
            <div className="alert alert-warning" role="alert">
              Do you want to <b>{currentEvent?.eventName}</b> cancel?
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default TotalEvents;
