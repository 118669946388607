import { combineReducers } from "redux";
import {
  FETCH_LOGIN_SUCCESS,
  ADD_LOGIN_SUCCESS,
  DELETE_LOGIN_SUCCESS,
} from "../actions/Login";

const initialStateForLogin = {
  login: [],
};

function login(state = initialStateForLogin, action) {
  // console.log("reducer call for Login");
  console.log(action);
  switch (action.type) {
    case FETCH_LOGIN_SUCCESS:
      return {
        ...state,
        //login: action.payload,
        login: JSON.parse(action.payload),
      };
    case ADD_LOGIN_SUCCESS:
      return {
        ...state,
        login: action.payload,
      };
    case DELETE_LOGIN_SUCCESS:
      return {
        ...state,
        login: [],
        //login: state.login.filter((item) => item.eventId !== action.payload),
      };

    default:
      return state;
  }
}

export default combineReducers({
  login,
});
