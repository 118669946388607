import React, { useState, useEffect } from "react";
import GetStateData from "../../../service/commonAPI";

//main function
export default function StateDropdown({ onStateChange, stateId, cameFrom }) {
  // declare start
  const [stateData, setStateData] = useState([]);
  const [stateId1, setStateId1] = useState(0);
  // console.log("chechStateId",stateId);
  //declare end

  //function/event/method
  const handleOnStateChange = (event) => {
    const stateId = event.target.value;
    if (onStateChange) onStateChange(stateId);
    setStateId1(stateId);
  };
  useEffect(() => {
    const fetchData = async () => {
      const response = await GetStateData();
      setStateData(response.data || []);
    };
    const fetchStateId = () => {
      setStateId1(stateId);
    };
    fetchStateId();
    fetchData();
  }, []); // The empty dependency array ensures that the effect runs only once when the component mounts

  //main return
  return (
    <>
      <div className="mx-sm-3 mb-2">
        {" "}
        {/* Use a div with a class for centering */}
        <div className="mb-3">
          {/* <label htmlFor="stateDropdown" className="form-label">
            Select State
          </label> */}
          <select
            id="stateDropdown"
            value={stateId1}
            className="form-select"
            onChange={handleOnStateChange}
            style={{
              marginTop: cameFrom === "CityPage" ? "-30px" : "0px",
              width: "450px", // Adjust the width
              marginLeft: cameFrom === "CityPage" ? "350px" : "-12px",
            }}
          >
            <option value="0">Select a state</option>
            {stateData.map((currentValue) => (
              <option key={currentValue.stateId} value={currentValue.stateId}>
                {currentValue.stateName}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
}
