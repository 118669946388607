import { put, call, takeEvery } from "redux-saga/effects";
import * as actions from "../actions/Login";
import * as comFun from "../../util/commonFun";
import * as constants from "../../util/Constants";

//Logins worker sagas
function* fetchLoginWorker() {
  // console.log("saga call for fetchLoginsWorker");
  try {
    const loginResponse = yield call(
      comFun.getLocalData,
      constants.LOCAL_STORAGE_USER
    );

    yield put(actions.fetchLoginSuccess(loginResponse));
  } catch (error) {
    console.error(error);
  }
}

function* addLoginWorker(action) {
  // console.log("saga call for addLoginWorker ");

  const token = action.payload;
  yield put(actions.addToLoginSuccess(action.payload));
}

function* deleteLoginWorker(action) {
  // console.log("saga call for deleteLoginWorker ");
  try {
    //yield call(userAPI.DeleteFromLogin, action.payload);
    yield put(actions.deleteFromLoginSuccess(action.payload));
  } catch (error) {
    console.error(error);
  }
}

//Logins Watcher Sagas
export function* watchFetchLogin() {
  // console.log("watchFetchLogins saga call2");
  yield takeEvery(actions.FETCH_LOGIN_REQUEST, fetchLoginWorker);
}

export function* watchAddLogin() {
  // console.log("watchAddLogins saga call2");
  yield takeEvery(actions.ADD_LOGIN_REQUEST, addLoginWorker);
}

export function* watchDeleteLogin() {
  // console.log("watchDeleteLogin saga call2");
  yield takeEvery(actions.DELETE_LOGIN_REQUEST, deleteLoginWorker);
}
